<template>
  <div class="exclusiveClients-view">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" style="position: relative">
      <div class="xing">*</div>
      <el-form-item label="联系人" prop="title">
        <el-col :xl="10">
          <div>
            <el-button
                class="addContact"
                @click="add"
            >添加联系人
            </el-button
            >
          </div>
          <div v-for="(item,index) in list" :key="index" class="input_wrap">
            <el-input
                placeholder="请输入联系人姓名"
                v-model="item.name"
                clearable
                maxlength="50" show-word-limit
            />
            <el-input
                placeholder="请输入联系人电话"
                v-model="item.phone"
                clearable
                maxlength="11" show-word-limit
                style="margin: 0 10px"
            />
            <el-button
                class="save"
                type="danger"
                @click="del(index,item)"
            >删除
            </el-button
            >
          </div>
        </el-col>
      </el-form-item>
      <el-form-item label="职责" prop="details">
        <el-col :xl="10">
          <!-- //富文本 -->
          <quill-editor @ready="ready($event)" style="height: 100%" v-model="ruleForm.details"
                        ref="myQuillEditor" :options="editorOption" @change="onEditorChange($event)"/>
          <span class="wordNumber">{{ TiLength }}/14000</span>
          <!--          <el-input type="textarea" v-model="ruleForm.details" maxlength="14000" show-word-limit></el-input>-->
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button
            class="save"
            type="primary"
            @click="submitForm('ruleForm')"
        >保存
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {lineHeightStyle} from "@/utils/lineHeight.js";
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

quillConfig.placeholder = '请填写内容'

import {
  addBusOfficePersonMore,
  upateBusOfficePerson,
  queryBusOfficePerson,
  delBusOfficePerson
} from '@/api/association.js'

export default {
  name: 'basicInformation',
  data() {
    return {
      TiLength: 0,
      editorOption: quillConfig,
      list: [],
      disabled: false,
      ruleForm: {
        details: "",
      },
      rules: {
        details: [{required: true, message: "请输入职责内容", trigger: "blur"}],
      },
      joinApplyId: '',
      isFlag: 2
    };
  },
  mounted() {
    this.getCon()
  },
  methods: {
    ready() {
      Quill.register({
        'formats/lineHeight': lineHeightStyle
      }, true);
    },
    onEditorChange(e) {
      if (this.ruleForm.details == '') {
        this.TiLength = 0
      } else {
        this.TiLength = e.quill.getLength() - 1
      }
    },
    getCon() {
      let ajax = {
        associationId: localStorage.getItem("associationId"),
        isLocal: 0,
      };
      queryBusOfficePerson(ajax)
          .then(res => {
            if (res.code == 200) {
              if (res.data) {
                this.isFlag = 1
                this.ruleForm.details = res.data.duty;
                this.list = res.data.addMorePerson
              } else {
                this.isFlag = 2
              }
            }
          })
    },
    submitForm(payload) {
      if (this.list.length == 0) {
        this.$message.error("请输入联系人!")
        return
      } else {
        for (let i in this.list) {
          const regex = /^1[3456789]\d{9}$/;//使用此正则即可
          if (!regex.test(this.list[i].phone)) {
            this.$message.error("请输入正确手机格式!")
            return
          }
        }
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let ajax = {
              associationId: localStorage.getItem("associationId"),
              duty: this.ruleForm.details,
              isLocal: 0,
              addMorePerson: this.list
            }
            if (this.isFlag == 2) {
              addBusOfficePersonMore(ajax)
                  .then(res => {
                    if (res.code == 200) {
                      this.$message({
                        type: 'success',
                        message: "保存成功",
                      })
                      this.getCon()
                    }
                  })
            } else {
              // ajax.joinApplyId = this.joinApplyId
              upateBusOfficePerson(ajax)
                  .then(res => {
                    if (res.code == 200) {
                      this.$message({
                        type: 'success',
                        message: "保存成功",
                      })
                      this.getCon()
                    }
                  })
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    add() {
      this.list.push({
        name: '',
        phone: ''
      })
    },
    async del(index, item) {
      if (item.personId) {
        this.list.splice(index, 1)
        await delBusOfficePerson({personId: item.personId});
      } else {
        this.list.splice(index, 1)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

/deep/ .el-textarea__inner {
  height: 400px;
}

.exclusiveClients-view {
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  padding: 24px;
}

.input_wrap {
  display: flex;
  margin-top: 10px;
}

.addContact {
  color: #FFF;
  background-color: #51CDCB;
  border-color: #51CDCB;
}

.xing {
  position: absolute;
  color: #F56C6C;
  top: 9px;
  left: -8px;
}
</style>
